<template>
  <div>
    <div class="p-grid">

      <div class="p-col-12">
        <Button 
          label="anexar boleto" 
          icon="pi pi-file" 
          class="p-button p-button-success button-contrato p-mr-3 p-mb-3" 
          @click="acao = 'AGUARDANDO_PAGAMENTO'" 
          v-show="acao == '' || acao == 'AGUARDANDO_PAGAMENTO'"
        />
        <Button 
          label="título emitido" 
          icon="pi pi-check" 
          class="p-button p-button-success p-mr-3 p-mb-3" 
          @click="acao = 'TITULO_EMITIDO'" 
          v-show="acao == '' || acao == 'TITULO_EMITIDO'"
        />
        <Button 
          label="registro recusado" 
          icon="pi pi-times" 
          class="p-button p-button-danger p-mr-3 p-mb-3" 
          @click="acao = 'RECUSADO'" 
          v-show="acao == '' || acao == 'RECUSADO'"
        />
        <Button 
          label="título cancelado" 
          icon="pi pi-times" 
          class="p-button p-button-danger p-mb-3" 
          @click="acao = 'TITULO_CANCELADO'" 
          v-show="acao == '' || acao == 'TITULO_CANCELADO'"
        />

      </div>

      <div class="p-col-12 p-mt-2" v-show="acao != ''">
        <label for="detalhes" class="p-d-block p-mb-2">Detalhes:</label>
        <Textarea id="detalhes" v-model="detalhes" :autoResize="true" rows="1" cols="30" style="width: 100%;" />
      </div>

      <div class="p-col-4" v-show="acao == 'TITULO_EMITIDO'">
        <label for="titulo" class="p-d-block p-mb-2">Número do Título:</label>
        <InputText
          id="titulo"
          type="text"
          v-model="titulo"
          :class="(fieldsError.some(v => v === 'titulo'))?'p-invalid':''"
          style="width: 100%"
        />
      </div>

      <div class="p-col-4" v-show="acao == 'TITULO_EMITIDO'">
        <label for="vigencia_inicio" class="p-d-block p-mb-2">Início da Vigência:</label>
        <InputMask
          v-model="vigencia_inicio"
          :class="(fieldsError.some(v => v === 'vigencia_inicio'))?'p-invalid':''"
          style="width: 100%"
          mask="99/99/9999"
          slotChar="dd/mm/aaaa"
        />
      </div>

      <div class="p-col-4" v-show="acao == 'TITULO_EMITIDO'">
        <label for="vigencia_fim" class="p-d-block p-mb-2">Fim da Vigência:</label>
        <InputMask
          v-model="vigencia_fim"
          :class="(fieldsError.some(v => v === 'vigencia_fim'))?'p-invalid':''"
          style="width: 100%"
          mask="99/99/9999"
          slotChar="dd/mm/aaaa"
        />
      </div>

      <div class="p-col-12 p-mt-2" v-show="acao != ''">
        <FileUpload 
          chooseLabel="Anexar Arquivos" 
          mode="basic" 
          name="upload[]" 
          :url="$api.url() + '/arquivos'"
          :auto="true" 
          :multiple="true"
          :maxFileSize="50000000"
          accept="image/*,video/*,application/pdf,application/zip"
          @before-send="upload_headers"
          @upload="upload_finalizado"
          @progress="upload_progress"
          @error="upload_error"
        >
        </FileUpload>
      </div>
      <div class="p-col-12" v-show="progress_upload > 0 && progress_upload < 100">
        <ProgressBar :value="progress_upload"></ProgressBar>
      </div>
      <div class="p-col-12 p-mt-2" v-show="arquivos.length > 0">
        <span v-for="a of arquivos" :key=a.id>
          <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="upload_click($event, a)"></Button>
        </span>
      </div>

      <div class="p-col-12" v-show="acao != ''">
        <Button label="Registrar" icon="pi pi-check" class="p-button p-button-success p-mt-4" @click="registrar($event)" />
      </div>

    </div>

    <ConfirmPopup></ConfirmPopup>
    <Toast position="top-right" />
  </div>
</template>

<script>

export default {
  props: {
    capitalizacao: Object,
  },
  emits: [
    "save"
  ],
  data() {
    return {

      acao: "",

      fieldsError: [],

      detalhes: "",
      titulo: "",
      vigencia_inicio: "",
      vigencia_fim: "",

      progress_upload: 0,
      arquivos : [],

    }
  },
  methods: {

    registrar(event) {

      this.fieldsError = [];

      let arquivos = [];
      if(this.arquivos.length > 0) {
        for(const a of this.arquivos) {
          arquivos.push(a.id);
        }
      }

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Confirma o registro?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-success',
        accept: () => {

          self.$api
            .post(
              "/capitalizacao/acao-manual",
              {
                "capitalizacao" : self.capitalizacao.id,
                "acao" : self.acao,
                "detalhes" : self.detalhes,
                "titulo": self.titulo,
                "vigencia_inicio": self.$utils.formatDateToMysql(self.vigencia_inicio),
                "vigencia_fim": self.$utils.formatDateToMysql(self.vigencia_fim),
                "arquivos": arquivos,
              }
            )
            .then(function (response) {

              self.$toast.add({
                severity: (response.data.success)?"success":"error",
                summary: (response.data.success)?"Sucesso!":"ERRO!",
                detail: response.data.message,
                life: (response.data.success)?3000:5000,
              });

              self.$emit("save");

            })
            .catch(function (erro) {

              if (typeof erro.response != "undefined") {

                const response = erro.response;

                if(typeof response.data.fields != "undefined") {

                  const dicionario = {            
                  };

                  for(const f of response.data.fields) {

                    if(typeof dicionario[f] != "undefined") {
                      self.fieldsError.push(dicionario[f]);
                    } else {
                      self.fieldsError.push(f);
                    }

                  }

                }

                self.$toast.add({
                  severity: "error",
                  summary: "ERRO!",
                  detail: response.data.message,
                  life: 5000,
                });

              }

            });

        }

      });

    },

    upload_headers(event) {
        event.xhr.setRequestHeader("Authorization", "Bearer " + this.$auth.token());
    },

    upload_progress(event) {
        this.progress_upload = event.progress;
    },

    upload_error(event) {
        const res = event.xhr.response;

        self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: res.message,
            life: 5000,
        });

    },

    upload_click(event, file) {

        const self = this;

        this.$confirm.require({
            target: event.currentTarget,
            message: 'O que deseja fazer?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Abrir",
            rejectLabel: "Excluir",
            acceptClass: "p-button-success",
            rejectClass: "p-button-danger",
            accept: () => {
                window.open(self.$api.url() + '/arquivos/' + file.filename_final, '_blank');
            },
            reject: () => {

                self.$api
                    .post(
                    "/arquivos/" + file.id, {"_method" : "DELETE"})
                    .then(function (response) {

                        const idx = self.arquivos.findIndex(e => e.id == file.id);
                        self.arquivos.splice(idx, 1);

                        self.$toast.add({
                            severity: (response.data.success)?"success":"error",
                            summary: (response.data.success)?"Sucesso!":"ERRO!",
                            detail: response.data.message,
                            life: (response.data.success)?3000:5000,
                        });

                    })
                    .catch(function (erro) {

                        if (typeof erro.response != "undefined") {

                            const response = erro.response;

                            self.$toast.add({
                                severity: "error",
                                summary: "ERRO!",
                                detail: response.data.message,
                                life: 5000,
                            });
                        }

                    });

            }
        });

    },

    upload_finalizado(event) {
        const res = JSON.parse(event.xhr.response);
        this.arquivos = this.arquivos.concat(res.data);
    },

  }
  
}
</script>
<style scoped>

  :deep(.p-selectbutton .p-button) {
    padding-top: 0.25rem;
    padding-bottom: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  :deep(.p-selectbutton) {
    display: inline-flex;
    margin-left: 1rem;
  }

  :deep(.p-button-success.button-contrato) {
    background-color: #f47921;
    border-color: #f47921;
  }

  :deep(.p-button-success.button-contrato:hover) {
    background-color: #f48e21;
    border-color: #f48e21;
  }

</style>