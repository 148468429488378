<template>
  <BlockUI :blocked="loading>0">
    <div class="p-grid">
      <div class="p-col-12 p-md-4">
        <h3>
          <i class="pi pi-file" style="font-size: 1.6rem"></i> CAP DIGITAL {{ (capitalizacao != null)?'#' + capitalizacao.id:'' }}
        </h3>
      </div>
      <div class="p-col-12 p-md-8 p-text-right">
        <Button label="registrar encaminhamento" icon="pi pi-check" class="p-button p-button-warning p-mr-3" v-if="$auth.currentUser.imobiliaria == null" @click="registrarEncaminhamento" />
        <Button label="novo pedido" icon="pi pi-plus" class="p-button p-button-secondary"  @click="$router.push({ path: `/capitalizacao` })" />
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12">

        <Accordion :multiple="true" v-model:activeIndex="tabsAtivas" class="w-full">

          <!-- #region Dados -->
          <AccordionTab header="Dados">

            <div class="p-grid">
              <div class="p-col-12">

                <div class="p-fluid">
                  <h5>
                    <i
                      class="pi pi-home"
                      style="font-size: 1.2rem; margin-right: 6px"
                    ></i>
                    Imóvel que está sendo alugado:
                  </h5>
                  <div class="p-grid">
                    <div
                      class="p-col-12 p-field selecao-detalhes"
                      v-if="typeof imovel == 'object' && imovel != null"
                    >
                      <div class="p-grid">
                        <div class="p-field p-md-2">
                          <label for="id_proprio">Identificação:</label>
                          <span>{{ imovel.id_proprio }}</span>
                        </div>
                        <div class="p-field p-md-10">
                          <label for="nome">Nome:</label>
                          <span>{{ imovel.nome }}</span>
                        </div>
                      </div>

                      <!-- Endereço -->
                      <div class="p-grid">
                        <div class="p-field p-md-2">
                          <label for="cep">CEP:</label>
                          <span>{{ imovel.cep }}</span>
                        </div>

                        <div class="p-field p-md-5">
                          <label for="logradouro">Endereço:</label>
                          <span>{{ imovel.logradouro }}</span>
                        </div>

                        <div class="p-field p-md-1">
                          <label for="numero">Número:</label>
                          <span>{{ imovel.numero }}</span>
                        </div>

                        <div class="p-field p-md-4">
                          <label for="complemento">Complemento:</label>
                          <span>{{ imovel.complemento }}</span>
                        </div>

                        <div class="p-field p-md-4">
                          <label for="bairro">Bairro:</label>
                          <span>{{ imovel.bairro }}</span>
                        </div>

                        <div class="p-field p-md-4">
                          <label for="cidade">Cidade:</label>
                          <span>{{ imovel.cidade }}</span>
                        </div>

                        <div class="p-field p-md-1">
                          <label for="uf">UF</label>
                          <span>{{ imovel.uf }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h5 v-if="typeof imovel == 'object' && imovel != null">
                    <i
                      class="pi pi-user"
                      style="font-size: 1.2rem; margin-right: 6px"
                    ></i>
                    Locador / Proprietário:
                  </h5>
                  <div class="p-grid" v-if="typeof imovel == 'object' && imovel != null">

                    <div
                      class="p-col-12 p-field selecao-detalhes"
                      v-if="
                        typeof locador == 'object' &&
                        locador != null &&
                        typeof locador.cpfcnpj != 'undefined'
                      "
                    >

                      <div class="p-grid">
                        <div class="p-field p-md-3" v-if="locador_tipo == 'PF'">
                          <label for="cpf">CPF:</label>
                          <span>{{ locador.cpfcnpj }}</span>
                        </div>

                        <div class="p-field p-md-3" v-else>
                          <label for="cpf">CNPJ:</label>
                          <span>{{ locador.cpfcnpj }}</span>
                        </div>

                        <div class="p-field p-md-9">
                          <label for="nome">
                            <span v-if="locador_tipo == 'PF'">Nome Completo</span>
                            <span v-else>Razão Social:</span>
                          </label>
                          <span>{{ locador.nome }}</span>
                        </div>

                        <div class="p-field p-md-3" v-if="locador_tipo == 'PF'">
                          <label for="rg">RG:</label>
                          <span>{{ locador.rg }}</span>
                        </div>

                        <div class="p-field p-md-3" v-if="locador_tipo == 'PF'">
                          <label for="rg_orgao">Órgão Emissor</label>
                          <span>{{ locador.rg_orgao }}</span>
                        </div>

                        <div class="p-field p-md-3" v-if="locador_tipo == 'PF'">
                          <label for="rg_expedicao">Expedição</label>
                          <span>{{ locador.rg_expedicao }}</span>
                        </div>

                        <div class="p-field p-md-3">
                          <label for="nascimento">
                            <span v-if="locador_tipo == 'PF'">Nascimento</span>
                            <span v-else>Fundação</span>
                          </label>
                          <span>{{ $utils.formatDateToBr(locador.nascimento) }}</span>
                        </div>

                        <div
                          :class="
                            locador_tipo == 'PF' ? 'p-field p-col-12' : 'p-field p-col-9'
                          "
                        >
                          <label for="email">E-Mail:</label>
                          <span>{{ locador.email }}</span>
                        </div>

                        <div class="p-field p-md-6" v-if="locador_tipo == 'PF'">
                          <label for="estado_civil">Estado Civil:</label>
                          <span>{{ locador.estado_civil_item.nome }}</span>
                        </div>

                        <div class="p-field p-md-6" v-if="locador_tipo == 'PF'">
                          <label for="sexo">Sexo:</label>
                          <span>{{ locador.sexo }}</span>
                        </div>
                      </div>

                      <div class="p-grid">
                        <div class="p-md-12" v-if="locador_tipo == 'PJ'">
                          <h6 class="p-mb-0">
                            <i class="pi pi-map-marker"></i> Endereço da Empresa
                          </h6>
                        </div>

                        <div class="p-field p-md-2">
                          <label for="residencia_cep">CEP:</label>
                          <span>{{ locador.cep }}</span>
                        </div>

                        <div class="p-field p-md-8">
                          <label for="residencia_logradouro">Endereço:</label>
                          <span>{{ locador.logradouro }}</span>
                        </div>

                        <div class="p-field p-md-2">
                          <label for="residencia_numero">Número:</label>
                          <span>{{ locador.numero }}</span>
                        </div>

                        <div class="p-field p-md-12">
                          <label for="residencia_complemento">Complemento:</label>
                          <span>{{ locador.complemento }}</span>
                        </div>

                        <div class="p-field p-md-5">
                          <label for="residencia_bairro">Bairro</label>
                          <span>{{ locador.bairro }}</span>
                        </div>

                        <div class="p-field p-md-5">
                          <label for="residencia_cidade">Cidade</label>
                          <span>{{ locador.cidade }}</span>
                        </div>

                        <div class="p-field p-md-2">
                          <label for="residencia_uf">UF</label>
                          <span>{{ locador.uf }}</span>
                        </div>
                      </div>

                      <div class="p-grid" v-if="locador != null && locador.contatos.length">
                        <div class="p-col-12">
                          <h6 class="p-mb-0"><i class="pi pi-phone"></i> Telefones</h6>
                        </div>

                        <div class="p-col-12 p-field">
                          <DataTable sortMode="single" :value="locador.contatos">
                            <Column
                              field="nome"
                              headerClass="colNome"
                              header="Observações"
                            >
                              <template #body="slotProps">
                                {{
                                  slotProps.data.nome ? slotProps.data.nome : "Nenhuma"
                                }}
                              </template>
                            </Column>
                            <Column
                              field="telefone"
                              headerClass="colTelefone"
                              headerStyle="width:145px"
                              header="Telefone"
                            >
                              <template #body="slotProps">
                                {{ $utils.formatPhone(slotProps.data.telefone) }}
                              </template>
                            </Column>
                            <Column
                              field="tipo"
                              headerClass="colTipo"
                              headerStyle="width:67px"
                              header="Tipo"
                            ></Column>
                            <Column
                              field="e_whatsapp"
                              headerClass="colWhatsapp"
                              headerStyle="width:65px"
                              header="Whatsapp?"
                            >
                              <template #body="slotProps">
                                {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
                              </template>
                            </Column>
                          </DataTable>
                        </div>
                      </div>

                      <div class="p-grid" v-if="locador_tipo == 'PJ'">
                        <div class="p-col-12">
                          <h6 class="p-mb-0">
                            <i class="pi pi-users"></i> Sócios Representantes
                          </h6>
                        </div>

                        <div class="p-col-12 p-field" v-if="locador.socios.length > 0">
                          <DataTable sortMode="single" :value="locador.socios">
                            <Column
                              field="socio_item.cpfcnpj"
                              headerClass="colCPF"
                              header="CPF"
                            ></Column>
                            <Column
                              field="socio_item.nome"
                              headerClass="colNome"
                              header="Nome"
                            ></Column>
                          </DataTable>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h5 v-if="typeof locador == 'object' && locador != null">
                    <i
                      class="pi pi-user"
                      style="font-size: 1.2rem; margin-right: 6px"
                    ></i>
                    Locatário (Titular):
                  </h5>

                  <div class="p-grid" v-if="locatario != null">
                    <div class="p-col-12 p-field selecao-detalhes">
                      <div class="p-grid">

                        <div class="p-field p-md-3" v-if="locatario_tipo == 'PF'">
                          <label for="cpf">CPF:</label>
                          <span>{{ locatario.cpfcnpj }}</span>
                        </div>

                        <div class="p-field p-md-3" v-else>
                          <label for="cpf">CNPJ:</label>
                          <span>{{ locatario.cpfcnpj }}</span>
                        </div>

                        <div class="p-field p-md-9">
                          <label for="nome">
                              <span v-if="locatario_tipo == 'PF'">Nome Completo</span>
                              <span v-else>Razão Social:</span>
                          </label>
                          <span>{{ locatario.nome }}</span>
                        </div>

                        <div class="p-field p-md-3" v-if="locatario_tipo == 'PF'">
                          <label for="rg">RG:</label>
                          <span>{{ locatario.rg }}</span>
                        </div>

                        <div class="p-field p-md-3" v-if="locatario_tipo == 'PF'">
                          <label for="rg_orgao">Órgão Emissor</label>
                          <span>{{ locatario.rg_orgao }}</span>
                        </div>

                        <div class="p-field p-md-3" v-if="locatario_tipo == 'PF'">
                          <label for="rg_expedicao">Expedição</label>
                          <span>{{ $utils.formatDateToBr(locatario.rg_expedicao) }}</span>
                        </div>

                        <div class="p-field p-md-3">
                          <label for="nascimento">
                              <span v-if="locatario_tipo == 'PF'">Nascimento</span>
                              <span v-else>Fundação</span>
                          </label>
                          <span>{{ $utils.formatDateToBr(locatario.nascimento) }}</span>
                        </div>

                        <div :class="locatario_tipo == 'PF'? 'p-field p-col-12' : 'p-field p-col-9'">
                          <label for="email">E-Mail:</label>
                          <span>{{ locatario.email }}</span>
                        </div>

                        <div class="p-field p-md-6" v-if="locatario_tipo == 'PF'">
                          <label for="estado_civil">Estado Civil:</label>
                          <span>{{ locatario.estado_civil_item.nome }}</span>
                        </div>

                        <div class="p-field p-md-6" v-if="locatario_tipo == 'PF'">
                          <label for="sexo">Sexo:</label>
                          <span>{{ locatario.sexo }}</span>
                        </div>

                      </div>

                      <div class="p-grid" v-if="locatario_tipo == 'PJ'">

                        <div class="p-md-12">
                          <h6 class="p-mb-0">
                              <i class="pi pi-map-marker"></i> Endereço da Empresa
                          </h6>
                        </div>

                        <div class="p-field p-md-2">
                          <label for="residencia_cep">CEP:</label>
                          <span>{{ locatario.cep }}</span>
                        </div>

                        <div class="p-field p-md-8">
                          <label for="residencia_logradouro">Endereço:</label>
                          <span>{{ locatario.logradouro }}</span>
                        </div>

                        <div class="p-field p-md-2">
                          <label for="residencia_numero">Número:</label>
                          <span>{{ locatario.numero }}</span>
                        </div>

                        <div class="p-field p-md-12">
                          <label for="residencia_complemento">Complemento:</label>
                          <span>{{ locatario.complemento }}</span>
                        </div>

                        <div class="p-field p-md-5">
                          <label for="residencia_bairro">Bairro</label>
                          <span>{{ locatario.bairro }}</span>
                        </div>

                        <div class="p-field p-md-5">
                          <label for="residencia_cidade">Cidade</label>
                          <span>{{ locatario.cidade }}</span>
                        </div>

                        <div class="p-field p-md-2">
                          <label for="residencia_uf">UF</label>
                          <span>{{ locatario.uf }}</span>
                        </div>

                      </div>

                      <div class="p-grid" v-if="locatario.contatos.length">

                        <div class="p-col-12">
                          <h6 class="p-mb-0"><i class="pi pi-phone"></i> Telefones</h6>
                        </div>

                        <div class="p-col-12 p-field">
                          <DataTable sortMode="single" :value="locatario.contatos">
                            <Column
                            field="nome"
                            headerClass="colNome"
                            header="Observações"
                            >
                            <template #body="slotProps">
                                {{
                                slotProps.data.nome ? slotProps.data.nome : "Nenhuma"
                                }}
                            </template>
                            </Column>
                            <Column
                            field="telefone"
                            headerClass="colTelefone"
                            headerStyle="width:145px"
                            header="Telefone"
                            >
                            <template #body="slotProps">
                                {{ $utils.formatPhone(slotProps.data.telefone) }}
                            </template>
                            </Column>
                            <Column
                            field="tipo"
                            headerClass="colTipo"
                            headerStyle="width:67px"
                            header="Tipo"
                            ></Column>
                            <Column
                            field="e_whatsapp"
                            headerClass="colWhatsapp"
                            headerStyle="width:65px"
                            header="Whatsapp?"
                            >
                            <template #body="slotProps">
                                {{ slotProps.data.e_whatsapp ? "Sim" : "Não" }}
                            </template>
                            </Column>
                          </DataTable>
                        </div>

                      </div>

                      <div class="p-grid" v-if="locatario_tipo == 'PJ'">
                        <div class="p-col-12">
                          <h6 class="p-mb-0">
                              <i class="pi pi-users"></i> Sócios Representantes
                          </h6>
                        </div>

                        <div class="p-col-12 p-field" v-if="locatario.socios.length > 0">
                          <DataTable sortMode="single" :value="locatario.socios">
                            <Column
                            field="socio_item.cpfcnpj"
                            headerClass="colCPF"
                            header="CPF"
                            ></Column>
                            <Column
                            field="socio_item.nome"
                            headerClass="colNome"
                            header="Nome"
                            ></Column>
                          </DataTable>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="p-grid selecao-detalhes" style="padding: 0 0.5rem;" v-if="typeof locatario == 'object' && locatario != null">
                    <div class="p-col-4 p-field">
                      <h5>
                        <i class="pi pi-dollar" style="font-size: 1.2rem; margin-right: 6px"></i>
                        Valor do Título:
                      </h5>
                      <div class="p-text-center">
                        <span style="font-size: 1.6rem;">
                          {{ this.$utils.formatCurrency(valor) }}
                        </span>
                      </div>
                    </div>
                    <div class="p-col-4 p-field">
                      <div v-if="valor > 0">
                        <h5>
                          <i class="pi pi-globe" style="font-size: 1.2rem; margin-right: 6px"></i>
                          Seguradora:
                        </h5>
                        <div class="p-text-center">
                          <img
                            :src="'/assets/layout/images/' + seguradora.logo"
                            :alt="seguradora.nome"
                            style="max-width: 100%; width: 130px"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="p-col-4 p-field">
                      <div v-if="typeof seguradora == 'object' && seguradora != null">
                        <h5>
                          <i class="pi pi-list" style="font-size: 1.2rem; margin-right: 6px"></i>
                          Plano:
                        </h5>
                        <div class="p-text-center">
                          <span style="font-size: 1.6rem;">
                            {{ plano.nome }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="p-grid selecao-detalhes" style="padding: 0 0.5rem; margin-top: 0" v-if="typeof titulo != 'undefined' && titulo">
                    <div class="p-col-4 p-field">
                      <label>
                        <i class="pi pi-file" style="font-size: 1.2rem; margin-right: 6px"></i>
                        Número:
                      </label>
                      <div class="p-text-center">
                        <span style="font-size: 1.2rem;">
                          {{ titulo }}
                        </span>
                      </div>
                    </div>
                    <div class="p-col-4 p-field">
                      <label>
                        <i class="pi pi-calendar" style="font-size: 1.2rem; margin-right: 6px"></i>
                        Início da Vigência:
                      </label>
                      <div class="p-text-center">
                        <span style="font-size: 1.2rem;">
                          {{ $utils.formatDateToBr(vigencia_inicio) }}
                        </span>
                      </div>
                    </div>
                    <div class="p-col-4 p-field">
                      <label>
                        <i class="pi pi-calendar" style="font-size: 1.2rem; margin-right: 6px"></i>
                        Fim da Vigência:
                      </label>
                      <div class="p-text-center">
                        <span style="font-size: 1.2rem;">
                          {{ $utils.formatDateToBr(vigencia_fim) }}
                        </span>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

          </AccordionTab>
          <!-- #endregion -->

          <AccordionTab header="Acompanhamentos">
            <div class="p-grid">

              <!-- #region Logs -->
              <div class="p-col-12 p-md-4">
                <div class="p-p-2">
                  <!-- <h5><strong>Logs:</strong></h5> -->
                  <div class="logs" v-if="logs.length > 0">
                    <div class="log p-grid p-mb-2" v-for="log of logs" :key="log.id">
                      <div class="p-col-12">
                        <strong><i class="pi pi-angle-right"></i> Executado por <i>{{ (log.usuario)?log.usuario_item.name + (log.usuario_item.imobiliaria_item?' (' + log.usuario_item.imobiliaria_item.nome + ')':' (Corretora)'):'Sistema' }}</i>
                        em <i>{{ $utils.formatDateTimeToBr(log.data) }}</i></strong>
                      </div>
                      <div class="p-col-12">
                        <div class="log_arquivos p-mb-3" v-if="log.arquivos.length > 0">
                            <div class="p-mb-2"><strong>Anexos:</strong></div>
                            <span v-for="a of log.arquivos" :key=a.id>
                                <Button :label="a.nome" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="anexo_abrir(a)"></Button>
                            </span>
                        </div>
                        <div class="log_descricao">
                        {{ log.resumo }}<br v-if="log.detalhes"><br v-if="log.detalhes"><i>{{ log.detalhes }}</i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    Nenhuma nova informação.
                  </div>
                </div>
              </div>
              <!-- #endregion -->

              <!-- #region Tickets -->
              <div class="p-col-12 p-md-8">
                <div class="p-pl-4 p-pr-2 p-py-2 p-ml-2" style="border-left: 1px solid #ccc; min-height: 130px">
                  <h5><strong>Tickets:</strong></h5>
                  <div class="tickets">

                    <Accordion class="">
                      <AccordionTab>
                        <template #header>
                          <span class="p-mr-2">Novo Ticket</span>
                          <i class="pi pi-plus"></i>
                        </template>

                        <div class="p-grid p-fluid">

                          <div class="p-col-12 p-md-6 p-field" v-if="$auth.currentUser.imobiliaria == null">
                            <label for="ticket_destinatario">Destinatário</label>
                            <div class="p-inputgroup">
                              <AutoComplete
                                id="ticket_destinatario"
                                v-model="ticket_destinatario"
                                :suggestions="ticket_destinatarioResultados"
                                @complete="ticket_destinatarioFiltrarAutoComplete($event)"
                                @blur="ticket_validaAutoComplete('ticket_destinatario')"
                                field="label"
                                optionGroupLabel="label"
                                optionGroupChildren="items"
                                placeholder="Informe parte do nome"
                                :class="
                                  fieldsError.some((v) => v === 'ticket_destinatario') ? 'p-invalid' : ''
                                "
                              />
                            </div>
                          </div>

                          <div class="p-field p-col-12">
                            <label for="ticket_assunto">Assunto</label>
                            <InputText
                            id="ticket_assunto"
                            type="text"
                            v-model="ticket_assunto"
                            :class="(fieldsError.some(v => v === 'ticket_assunto'))?'p-invalid':''"
                            maxlength="100"
                            />
                          </div>

                          <div class="p-field p-col-12">
                            <label for="ticket_mensagem">Mensagem</label>
                            <Textarea 
                            :autoResize="true"
                            id="ticket_mensagem"
                            v-model="ticket_mensagem"
                            :class="(fieldsError.some(v => v === 'ticket_mensagem'))?'p-invalid':''"
                            rows="5" cols="30"
                            />
                          </div>

                          <div class="p-field p-col-12" v-if="$auth.currentUser.imobiliaria == null">
                            <label for="ticket_mensagem_corretora">Mensagem Interna (Corretora)</label>
                            <Textarea 
                            :autoResize="true"
                            id="ticket_mensagem_corretora"
                            v-model="ticket_mensagem_corretora"
                            :class="(fieldsError.some(v => v === 'ticket_mensagem_corretora'))?'p-invalid':''"
                            rows="1" cols="30"
                            />
                          </div>                        

                          <div class="p-col-12">
                              <FileUpload 
                                  chooseLabel="Anexar Arquivos" 
                                  mode="basic" 
                                  name="upload[]" 
                                  :url="$api.url() + '/arquivos'"
                                  :auto="true" 
                                  :multiple="true"
                                  :maxFileSize="50000000"
                                  accept="image/*,video/*,application/pdf,application/zip"
                                  @before-send="ticket_upload_headers"
                                  @upload="ticket_upload_finalizado"
                                  @progress="ticket_upload_progress"
                                  @error="ticket_upload_error"
                              >
                              </FileUpload>
                          </div>
                          <div class="p-col-12" v-if="ticket_progress_upload > 0 && ticket_progress_upload < 100">
                              <ProgressBar :value="ticket_progress_upload"></ProgressBar>
                          </div>
                          <div class="p-col-12" v-if="ticket_arquivos.length > 0">
                              <span v-for="a of ticket_arquivos" :key=a.id>
                                  <Button :label="a.filename_original" class="p-mr-2 p-button-secondary p-button-sm" style="width: auto" icon="pi pi-file" @click="ticket_upload_click($event, a)"></Button>
                              </span>
                          </div>
                          <div class="p-col-12">
                              <Button label="Criar Ticket" class="p-button-lg p-button-success" style="width: auto" icon="pi pi-check" @click="ticket_criar"></Button>
                          </div>
                        </div>
                      </AccordionTab>
                    </Accordion>

                    <div class="p-fluid p-my-3">

                      <DataTable sortMode="single" :value="tickets" :loading="loading>0" selectionMode="single" v-model:selection="ticket">
                        <Column field="id" header="#" class="col-id"></Column>
                        <Column field="criador_item.name" header="Criador" class="col-criador">
                          <template #body="slotProps">
                            {{ slotProps.data.criador_item.name }} ({{ (!slotProps.data.criador_item.imobiliaria_item)?'Corretora':slotProps.data.criador_item.imobiliaria_item.nome }})
                          </template>
                        </Column>
                        <Column header="Aguardando" class="col-aguardando">
                          <template #body="slotProps">
                            <div v-if="slotProps.data.atual_status_destinatario == 'CONCLUIDO'">
                              -
                            </div>
                            <div v-else-if="slotProps.data.atual_destinatario">
                            {{ slotProps.data.atual_destinatario_item.name }} ({{ (!slotProps.data.atual_destinatario_item.imobiliaria_item)?'Corretora':slotProps.data.atual_destinatario_item.imobiliaria_item.nome }})
                            </div>
                            <div v-else-if="slotProps.data.atual_destinatario_imobiliaria">
                            {{ slotProps.data.atual_destinatario_imobiliaria_item.nome }} (Imobiliária)
                            </div>
                            <div v-else>
                              Corretora
                            </div>
                          </template>
                        </Column>
                        <Column field="data" header="Data de Criação" class="col-data">
                          <template #body="slotProps">
                            {{ $utils.formatDateTimeToBr(slotProps.data.data) }}
                          </template>
                        </Column>
                        <Column field="atual_interacao_data" header="Última Interação" class="col-ultima_interacao">
                          <template #body="slotProps">
                            {{ $utils.formatDateTimeToBr(slotProps.data.atual_interacao_data) }}
                          </template>
                        </Column>
                        <Column field="assunto" header="Assunto" class="col-assunto">
                          <template #body="slotProps">
                            {{ slotProps.data.assunto }}
                          </template>                
                        </Column>
                        <Column field="status" header="Status" class="col-status">
                          <template #body="slotProps">
                            <Tag 
                              v-if="$auth.currentUser.id == slotProps.data.atual_remetente"
                              :value="tickets_status[slotProps.data.atual_status_remetente].label"
                              :icon="tickets_status[slotProps.data.atual_status_remetente].icon" 
                              :severity="tickets_status[slotProps.data.atual_status_remetente].severity">
                            </Tag>
                            <Tag 
                              v-else
                              :value="tickets_status[slotProps.data.atual_status_destinatario].label"
                              :icon="tickets_status[slotProps.data.atual_status_destinatario].icon" 
                              :severity="tickets_status[slotProps.data.atual_status_destinatario].severity">
                            </Tag>
                          </template>
                        </Column>
                        <template #empty>
                            Nenhum Ticket encontrado.
                        </template>
                      </DataTable>

                    </div>
                    
                  </div>
                </div>
              </div>
              <!-- #endregion -->

            </div>
          </AccordionTab>

        </Accordion>

      </div>
    </div>

  </BlockUI>

  <Dialog v-model:visible="displayAcoes" style="max-width: 98%; width: 907px" :modal="true">
    <template #header>
      <h5>Registrar Encaminhamento Manual</h5>
    </template>
    <CapitalizacaoAcaoEdit :capitalizacao="capitalizacao" @save="encaminhamentoRegistrado" />
  </Dialog>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>

import CapitalizacaoAcaoEdit from "./acoes/CapitalizacaoAcaoEdit.vue";

export default {
  data() {
    return {
      // Controle de apresentação
      loading: 1,
      tabsAtivas: [1],
      displayAcoes: false,

      // Validações

      fieldsError: [],

      // Chaves estrangeiras
      seguradoras: [
        {
          id: 9,
          nome: "ICATU",
          logo: "logo-icatu-seguros.png",
          planos: [
            {
              id: 12,
              nome: "12 Meses",
            },
            {
              id: 15,
              nome: "15 Meses",
            },
          ],
        },
        {
          id: 10,
          nome: "BRASILCAP (Banco do Brasil)",
          logo: "logo-brasilcap.png",
          planos: [
            {
              id: 12,
              nome: "12 Meses",
            },
            {
              id: 15,
              nome: "15 Meses",
            },
            {
              id: 30,
              nome: "30 Meses",
            },
          ],
        },
        {
          id: 4,
          nome: "PORTO SEGURO",
          logo: "logo-porto-seguro.png",
          planos: [
            {
              id: 18,
              nome: "18 Meses",
            },
            {
              id: 24,
              nome: "24 Meses",
            },
            {
              id: 30,
              nome: "30 Meses",
            },
          ],
        },
      ],

      // Formulário

      imobiliaria: null,
      imovel: null,
      locatario_tipo: "",
      locatario: null,
      locador_tipo: "",
      locador: null,
      valor: 0,
      seguradora: null,
      plano: null,
      data: "",
      vigencia_inicio: "",
      vigencia_fim: "",
      titulo: "",
      logs: [],

      //3.2. Tickets

      ticket_destinatario: null,
      ticket_destinatarioResultados: [],
      ticket_assunto: "",
      ticket_mensagem: "",
      ticket_mensagem_corretora: "",

      ticket_progress_upload: 0,
      ticket_arquivos: [],

      ticket: null,
      tickets: [],
      tickets_status: {
        'NOVO' : {
          label: 'NOVO',
          severity: "info",
          icon: "pi pi-clock",
        },
        'RESPONDIDO' : {
          label: 'RESPONDIDO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'AGUARDANDO' : {
          label: 'AGUARDANDO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'CONCLUIDO': {
          label: 'CONCLUÍDO',
          severity: "success",
          icon: "pi pi-check",
        },
        'ARQUIVADO': {
          label: 'ARQUIVADO',
          severity: "danger",
          icon: "pi pi-times",
        },
      },

      // Dado para mostrar

      capitalizacao: null,

    };

  },
  mounted() {

    this.carregarObjetoRota();

  },
  watch: {
    $route() {
      if(this.$route.name == "capitalizacaoshow")
        this.carregarObjetoRota();
    },

    capitalizacao(val) {

      const seguradora = this.seguradoras.filter((obj) => obj.id == val.seguradora)[0];
      const plano = seguradora.planos.filter((obj) => obj.id == val.plano)[0];

      this.imobiliaria = val.imobiliaria_item;
      this.imovel = val.imovel_item;
      this.seguradora = seguradora;
      this.valor = val.valor;
      this.plano = plano;
      this.data = val.data;
      this.titulo = val.titulo;
      this.vigencia_inicio = val.vigencia_inicio;
      this.vigencia_fim = val.vigencia_fim;
      this.locatario_tipo = val.locatario_item.tipo;
      this.locatario = val.locatario_item;
      this.locador_tipo = val.locador_item.tipo;
      this.locador = val.locador_item;

      // TODO PASSAR O FILTRO DE LOGS POR USUÁRIO PARA A API

      this.logs = [];

      for(const l of val.logs) {

        if(l.acao == 'AGUARDANDO_PAGAMENTO' || l.acao == 'TITULO_EMITIDO')
          this.logs.push(l);
        else if(this.$auth.currentUser.imobiliaria == null) {
          this.logs.push(l);
        }

      }

    },

    ticket: function(val) {
      if(val != null) {
        this.$router.push({ path: `/ticket/${val.id}` });
      }
    },

  },
  methods: {
    carregarObjetoRota() {
      const self = this;

      if (
          typeof this.$route.params.id != "string" ||
          this.$route.params.id == ""
      ) {
          self.loading--;
          return;
      }

      this.$api
      .get("/capitalizacao/" + this.$route.params.id)
      .then(function (response) {
          const dados = response.data;
          if (dados.success) { 
              self.capitalizacao = dados.data;

              self.carregaTickets();

              self.loading--;
          } else {
              alert(dados.message);
              self.$router.push({ path: `/capitalizacoes` });
          }
      });

    },
    anexo_abrir(arquivo) {
      window.open(this.$api.url() + '/arquivos/' + arquivo.nome, '_blank');
    },
    registrarEncaminhamento() {
      this.displayAcoes = true;
    },
    encaminhamentoRegistrado() {
      this.displayAcoes = false;
      this.loading++;
      this.carregarObjetoRota();
    },
    carregaTickets() {
      const self = this;

      self.loading++;

      const api_get = '?fields=id,data,atual_interacao_data,assunto,atual_status_destinatario,atual_status_remetente&relationships=criador_item:name;atual_destinatario_item:name;atual_destinatario_imobiliaria_item:nome&filters={%22capitalizacao%22:[%22' + this.capitalizacao.id + '%22]}';

      this.$api.get('/tickets' + api_get).then(function (response) {

        const dados = response.data;

        if(dados.success) {
          self.tickets = dados.data;
        }

        self.loading--;
        
      });

    },

    ticket_validaAutoComplete(val) {
      if(typeof this[val] != "object")
        this[val] = null;
    },

    ticket_destinatarioFiltrarAutoComplete(event) {

      const self = this;

      const q = event.query.toLowerCase();

      this.$api
        .get("/tickets/destinatarios?str=" + q)
        .then(function (response) {
          const dados = response.data;
          if (dados.success) {

            self.ticket_destinatarioResultados = dados.data;

          }
        });

    },

    ticket_upload_headers(event) {
        event.xhr.setRequestHeader("Authorization", "Bearer " + this.$auth.token());
    },

    ticket_upload_progress(event) {
        this.ticket_progress_upload = event.progress;
    },

    ticket_upload_error(event) {
        const res = event.xhr.response;

        self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: res.message,
            life: 5000,
        });

    },

    ticket_upload_click(event, file) {

        const self = this;

        this.$confirm.require({
            target: event.currentTarget,
            message: 'O que deseja fazer?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Abrir",
            rejectLabel: "Excluir",
            acceptClass: "p-button-success",
            rejectClass: "p-button-danger",
            accept: () => {
                window.open(this.$api.url() + '/arquivos/' + file.filename_final, '_blank');
            },
            reject: () => {

                self.$api
                    .post(
                    "/arquivos/" + file.id, {"_method" : "DELETE"})
                    .then(function (response) {

                        const idx = self.ticket_arquivos.findIndex(e => e.id == file.id);
                        self.ticket_arquivos.splice(idx, 1);

                        self.$toast.add({
                            severity: (response.data.success)?"success":"error",
                            summary: (response.data.success)?"Sucesso!":"ERRO!",
                            detail: response.data.message,
                            life: (response.data.success)?3000:5000,
                        });

                    })
                    .catch(function (erro) {

                        if (typeof erro.response != "undefined") {

                            const response = erro.response;

                            self.$toast.add({
                                severity: "error",
                                summary: "ERRO!",
                                detail: response.data.message,
                                life: 5000,
                            });
                        }

                    });

            }
        });

    },

    ticket_upload_finalizado(event) {
        const res = JSON.parse(event.xhr.response);
        this.ticket_arquivos = this.ticket_arquivos.concat(res.data);
    },

    ticket_limparFormulario() {

      this.ticket_assunto = "";
      this.ticket_mensagem = "";
      this.ticket_mensagem_corretora = "";

      for(const a of this.ticket_arquivos) {
        this.$api.post("/arquivos/" + a.id, {"_method" : "DELETE"});
      }

      this.ticket_arquivos = [];

    },

    ticket_criar() {
      const self = this;

      this.fieldsError = [];

      let dados = {
        "assunto": this.ticket_assunto,
        "mensagem": this.ticket_mensagem,
        "mensagem_corretora": this.ticket_mensagem_corretora,
        "capitalizacao": this.capitalizacao.id,
      };

      if(this.ticket_destinatario)
        dados[this.ticket_destinatario.field] = this.ticket_destinatario.id;

      if(this.ticket_arquivos.length > 0) {
        dados['arquivos'] = [];
        for(const a of this.ticket_arquivos) {
          dados['arquivos'].push(a.id);
        }
      }

      this.$api
        .post(
          "/tickets",
          dados
        )
        .then(function (response) {

          self.$toast.add({
            severity: (response.data.success)?"success":"error",
            summary: (response.data.success)?"Sucesso!":"ERRO!",
            detail: response.data.message,
            life: (response.data.success)?3000:5000,
          });

          self.ticket_arquivos = [];

          self.ticket_limparFormulario();

          self.carregaTickets();

        })
        .catch(function (erro) {

          if (typeof erro.response != "undefined") {

            const response = erro.response;

            if(typeof response.data.fields != "undefined") {

              const dicionario = {

                assunto: "ticket_assunto",
                mensagem: "ticket_mensagem",
                mensagem_corretora: "ticket_mensagem_corretora",
                mensagem_interna: "ticket_mensagem_interna",
                destinatario: "ticket_destinatario",
                arquivos: "ticket_arquivos",

              };

              for(const f of response.data.fields) {

                if(typeof dicionario[f] != "undefined") {
                  self.fieldsError.push(dicionario[f]);
                } else {
                  self.fieldsError.push(f);
                }

              }

            }

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: response.data.message,
              life: 5000,
            });

          }

        });
    },

  },
  components: {
    CapitalizacaoAcaoEdit
  }

};
</script>

<style scoped>

.selecao-detalhes .p-field {
  border: 1px solid #ccc;
  margin: 0;
  padding: 0.3rem 0.4rem;
  margin-top: -1px;
}

.selecao-detalhes .p-field span {
  display: block;
}

.selecao-detalhes .p-field label {
  font-weight: 500;
  margin-bottom: 4px;
}

.selecao-detalhes .p-grid {
  margin: 0;
}

:deep(.selecao-detalhes) th,
:deep(.selecao-detalhes) td {
  background: none !important;
  padding: 0.5rem !important;
}

</style>